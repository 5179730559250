import { Spin } from "antd";
import { useRouter } from "next/router";
import { createContext, ReactNode, useEffect, useState } from "react";

const Context = createContext(false);

type Props = {
  children: ReactNode;
};

export const Provider = ({ children }: Props) => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setIsLoading(true);
    };
    const handleRouteChangeEnd = () => {
      setIsLoading(false);
    };

    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChangeEnd);

    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChangeEnd);
    };
  }, [router.events]);

  return (
    <Context.Provider value={isLoading}>
      <Spin spinning={isLoading}>{children}</Spin>
    </Context.Provider>
  );
};

// Comment out since we're not using it right now.
// export const useRouterLoading = () => useContext(Context);
