import Script from "next/script";

type AnalyticsProps = {
  measurementId: string;
};

/**
 * Load scripts for Google Analytics v4.
 *
 * @see https://nextjs.org/docs/basic-features/script#afterinteractive
 */
export const Analytics = ({ measurementId }: AnalyticsProps) => {
  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${measurementId}`}
      />

      <Script
        id="google-analytics-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${measurementId}');`,
        }}
      />
    </>
  );
};
