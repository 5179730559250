import c from "classnames";

/**
 * Like Object.assign but merges properties like className (using classnames) and style.
 * @param {...object} props Array of props to be merged.
 * @return {object} Final merged props.
 */
//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getMergedProps = <T extends Record<string, any>>(
  ...props: T[]
): T => {
  return props.reduce<T>((current, next) => {
    return {
      ...next,
      className: c(current.className, next.className),
      style: { ...current.style, ...next.style },
    };
  }, {} as T);
};
