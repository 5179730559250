import { useLayoutEffect, useRef } from "react";

/**
 * On mobile devices we had a problem where 100vh will be larger than the visible
 * height when the URL bar is shown. This adds a scroll so the footer is hidden when
 * it is loaded.
 * We fixed this using the measure `height: 100%`, but for this to works we need to
 * set `height: 100%` for all its parent elements.
 */
export const useSetFullHeight = <T extends HTMLElement>() => {
  const elementRef = useRef<T>(null);

  useLayoutEffect(() => {
    // Set height to 100% to given element and its parents.
    const setFullHeight = (element: HTMLElement) => {
      element.style.height = "100%";
      if (element.parentElement) {
        setFullHeight(element.parentElement);
      }
    };
    setFullHeight(elementRef.current);
  }, [elementRef]);

  return elementRef;
};
