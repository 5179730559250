import * as Sentry from "@sentry/nextjs";
import { useRouter } from "next/router";
import { ReactNode } from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";

import { ClientError } from "~/src/components/error-pages";

export class PageError extends Error {
  message: string;
  title: string;

  constructor({ title, message }) {
    super("PageError");
    this.title = title;
    this.message = message;
  }
}

type Props = {
  children: ReactNode;
};

// Capture errors to Sentry.
const handleError = (error: Error, { componentStack }) => {
  Sentry.captureException(error, { contexts: { react: { componentStack } } });
};

export const ErrorBoundary = ({ children }: Props) => {
  const router = useRouter();

  return (
    <ReactErrorBoundary
      fallbackRender={({ error }) => {
        let title: string;
        let message: string;
        if (error instanceof PageError) {
          title = error.title;
          message = error.message;
        }

        return <ClientError title={title} message={message} />;
      }}
      resetKeys={[router.asPath]}
      onError={handleError}
    >
      {children}
    </ReactErrorBoundary>
  );
};
