var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"EPuRWqoiFewE6rqHg24bs"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { init } from "@sentry/nextjs";
import { AxiosError } from "axios";

/**
 * @see https://docs.sentry.io/platforms/javascript/guides/nextjs/
 */

init({
  // DSN is the endpoint for data ingestion.
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

  // Define the environment for logs.
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,

  // Adjust this value in production, or use tracesSampler for greater control.
  tracesSampleRate: 1.0,

  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps.

  // Add request/response data to the event.
  beforeSend(event, hint) {
    if (hint.originalException instanceof AxiosError) {
      event.extra = event.extra ?? {};
      event.extra.originalException = hint.originalException.toJSON();
    }
    return event;
  },
});
