/**
 * Encode values for querysring.
 */
const encode = (value: unknown): undefined | string => {
  switch (typeof value) {
    case "number":
      if (isNaN(value)) {
        return;
      }
      return value.toString();
    case "string":
    case "boolean":
      return value.toString();
    case "object":
      if (Array.isArray(value) && value.length > 0) {
        return value.join();
      }
      return;
    default:
      return;
  }
};

/**
 * Serialize an object into a querystring.
 */
export const serialize = (params: Record<string, unknown>) => {
  const normalized: Record<string, string> = {};
  Object.keys(params).forEach((key) => {
    const value = encode(params[key]);
    if (value !== undefined) {
      normalized[key] = value;
    }
  });
  return new URLSearchParams(normalized).toString();
};
