import { notification } from "antd";
import { NotificationApi } from "antd/lib/notification";
import { createContext, ReactNode, useContext, useMemo } from "react";

type Instance = Exclude<NotificationApi, "useNotification">;

const Context = createContext<Instance>(null);

type Props = {
  children?: ReactNode;
};

export const Provider = ({ children }: Props) => {
  const [instance, contextHolder] = notification.useNotification();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const value = useMemo(() => ({ ...notification, ...instance }), []);

  return (
    <Context.Provider value={value}>
      {contextHolder}
      {children}
    </Context.Provider>
  );
};

/**
 * Access to notification contextualized static functions.
 * @see https://ant.design/components/notification/#components-notification-demo-hooks
 */
export const useNotification = () => {
  return useContext(Context);
};
