import { LayoutTree } from "@moxy/next-layout";
import { getCurrentScope } from "@sentry/nextjs";
import { appWithTranslation } from "next-i18next";
import { AppProps } from "next/app";
import React, { useEffect } from "react";

import nextI18NextConfig from "~/next-i18next.config.js";
import { Analytics } from "~/src/components/analytics";
import { AntdConfig } from "~/src/components/antd-config";
import { ErrorBoundary } from "~/src/components/error-boundary";
import { ReactQueryConfig } from "~/src/components/react-query-config";
import { Provider as ModalProvider } from "~/src/lib/modal";
import { Provider as NotificationProvider } from "~/src/lib/notification";
import { getRandomId } from "~/src/lib/random-id";
import { setDeviceId } from "~/src/lib/session";
import { Provider as RouterLoadingProvider } from "~/src/lib/use-router-loading";

import "~/src/lib/dayjs";

/**
 * Fix issue with useLayoutEffect in server-side since it's not supported.
 *
 * This works because a few things:
 * 1. Once a library is imported its content is cached and every subsequent import resolves to the same object.
 * 2. This configuration file is executed very early during the build.
 * 3. The import cache is a Node.js thing so it doesn't affect the browser runtime.
 *
 * @todo Ideally this fix should go away once the affected components are updated.
 * @see https://github.com/ant-design/ant-design/issues/30396
 * @see https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85
 */
if (typeof window === "undefined") {
  // eslint-disable-next-line import/no-named-as-default-member
  React.useLayoutEffect = useEffect;
}

/**
 * Global stylesheet. Must use require instead of import for global styles!
 * @see https://github.com/SolidZORO/next-plugin-antd-less
 */
import "~/src/styles/global.css";

const App = ({ Component, pageProps }: AppProps) => {
  const googleAnalyticsMeasurementId =
    process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID;

  useEffect(() => {
    const deviceId = getRandomId();

    setDeviceId(deviceId);

    getCurrentScope().setTag("deviceId", deviceId);
  }, []);

  return (
    <ErrorBoundary>
      {googleAnalyticsMeasurementId ? (
        <Analytics measurementId={googleAnalyticsMeasurementId} />
      ) : null}

      <AntdConfig>
        <ReactQueryConfig>
          <RouterLoadingProvider>
            <NotificationProvider>
              <ModalProvider>
                <LayoutTree Component={Component} pageProps={pageProps} />
              </ModalProvider>
            </NotificationProvider>
          </RouterLoadingProvider>
        </ReactQueryConfig>
      </AntdConfig>
    </ErrorBoundary>
  );
};

export default appWithTranslation(App, nextI18NextConfig);
