const path = require("path");

const dayjs = require("dayjs");

// Pre-load dayjs locales.
require("dayjs/locale/pt-br");

// Where namespace files are stored.
const localePath = path.resolve("./public/locales");

// Which extension is used to encode the data.
const localeExtension = "json";

// Default language code.
const defaultLocale = "pt-BR";

/**
 * @type {import('next-i18next').UserConfig}
 */
module.exports = {
  i18n: {
    defaultLocale,
    locales: [defaultLocale, "en-US"],
    localeDetection: false,
  },

  localePath,
  localeExtension,
  serializeConfig: false,
  strictMode: false,

  // On the server side next-i18next collects all the namespace
  // files and loads them upfront. It's only on the client that
  // they are loaded individually.
  // At this time the namespaces are collected shallowly, i.e.
  // only files directly under the default language directory
  // are considered. Since we're using subdirectories we need
  // to change this to a recursive algorithm.
  // This code looks similar to what next-i18next does by default,
  // see https://github.com/isaachinman/next-i18next/blob/abdf06545410f340b0529e3448f8b102ab840249/src/config/create-config.ts#L99
  // Also we require glob inline to avoid having
  // it included in the client side bundle.
  ns:
    typeof window === "undefined"
      ? require("glob")
          .sync(`${localePath}/${defaultLocale}/**/*.${localeExtension}`)
          .map((file) => {
            return file.replace(
              new RegExp(
                `${localePath}/${defaultLocale}/(.+?).${localeExtension}`,
              ),
              "$1",
            );
          })
      : ["common"],

  // Reload localization strings during development.
  reloadOnPrerender: process.env.NODE_ENV === "development",

  // The i18next-fs-backend is the package responsible to read
  // locale data of a given namespace from the filesystem.
  // At this time it encodes slashes in namespaces;
  // @see https://github.com/i18next/i18next-fs-backend/issues/13.
  // Since we're using subdirectories we can make it work
  // by providing a loadPath function instead of string.
  // @see https://github.com/i18next/i18next-fs-backend#backend-options.
  backend: {
    loadPath: (lang, ns) => {
      if (typeof window === "undefined") {
        return `/${localePath}/${lang}/${ns}.${localeExtension}`;
      }
      return `/${localePath.substr(8)}/${lang}/${ns}.${localeExtension}`;
    },
  },

  use: [
    // Custom format options.
    // @see https://www.i18next.com/translation-function/formatting#adding-custom-format-function
    // Custom format options is added here, because, into de i18next config file,
    // currently it is the only option to access the i18next instance after init.
    // There may be a better way to set this config in the future.
    // @see https://github.com/isaachinman/next-i18next/issues/1535#issuecomment-989563213
    {
      type: "3rdParty",
      init: (i18next) => {
        // Date and time format via Dayjs, e.g. Jan 20th 2020.
        i18next.services.formatter.add(
          "datetime",
          (value, locale, { format }) => {
            return dayjs(value).locale(locale).format(format);
          },
        );

        // Duration (or period) in words, e.g. 1 month ago or in 2 hours.
        i18next.services.formatter.add("duration", (value, locale) => {
          const duration = dayjs.duration(Math.abs(value));

          const y = duration.years();
          const M = duration.months();
          const d = duration.days();
          const h = duration.hours();
          const m = duration.minutes();
          const s = duration.seconds();

          const result = [];

          const push = (duration, unit) => {
            return result.push(
              dayjs.duration(duration, unit).locale(locale).humanize(),
            );
          };

          if (y > 0) {
            push(y, "years");

            if (M > 0) {
              push(M, "months");
            }

            return result.join(", ");
          }

          if (M > 0) {
            push(M, "months");

            if (d > 0) {
              push(d, "days");
            }

            return result.join(", ");
          }

          if (d > 0) {
            push(d, "days");

            if (h > 0) {
              push(h, "hours");
            }

            return result.join(", ");
          }

          if (h > 0) {
            push(h, "hours");

            if (m > 0) {
              push(m, "minutes");
            }

            return result.join(", ");
          }

          if (m > 0) {
            push(m, "minutes");
          }
          push(s, "seconds");

          return result.join(", ");
        });

        // Capitalize first word, e.g. hello, world -> Hello, world.
        i18next.services.formatter.add("capitalize", (value) => {
          return value.charAt(0).toUpperCase() + value.slice(1);
        });

        // Bytes to megabytes, e.g. 1024 bytes -> 1 MB.
        i18next.services.formatter.add(
          "megabytes",
          (value, _, { precision = 0 }) => {
            return (value / 1024 / 1024).toFixed(precision) + "MB";
          },
        );
      },
    },
  ],

  interpolation: {
    // i18next escape strings, which is a security feature,
    // but React also escape strings leading to a escape-ception. 😬️
    // @see https://www.i18next.com/translation-function/interpolation#all-interpolation-options
    escapeValue: false,
  },
};
