const termsOfUseUrl = "https://www.bluy.com/termos-de-uso/";
const privacyPolicyUrl = "https://www.bluy.com/politica-de-privacidade/";
const signUpUrl = "https://www.bluy.com/contato-para-empresas/";
const stripeUrl = "https://billing.stripe.com/p/login/4gw7vj1R356i56E5kk";
const plansUrl = "https://www.bluy.com/planos/";

/**
 * We need to change the support URL to an internal page.
 *
 * const supportUrl = "https://help.bluy.com";
 * const applicantSupportUrl =
 *  "https://help.bluy.com/hc/pt-br/requests/new?ticket_form_id=12521890696731";
 */
const supportUrl = "/help";
const applicantSupportUrl = "/help";

/**
 * Return the terms of use URL.
 */
export function getTermsOfUseUrl() {
  return termsOfUseUrl;
}

/**
 * Return the privacy policy URL.
 */
export function getPrivacyPolicyUrl() {
  return privacyPolicyUrl;
}

/**
 * Return sign-up URL.
 */
export function getSignUpUrl() {
  return signUpUrl;
}

/**
 * Return Stripe URL.
 */
export function getStripeUrl() {
  return stripeUrl;
}

/**
 * Return the plans page URL.
 */
export function getPlansUrl() {
  return plansUrl;
}

/**
 * Return the support URL.
 */
export function getSupportUrl() {
  return supportUrl;
}

/**
 * Return the URL to open a new ticket.
 */
export function getApplicantSupportRequestUrl() {
  return applicantSupportUrl;
}
