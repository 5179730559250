import * as Sentry from "@sentry/nextjs";
import { ReactNode } from "react";
import { QueryClient, QueryClientProvider, setLogger } from "react-query";

import { NotFoundError, Unauthorized } from "~/src/lib/api";

// Set the logger to use Sentry.
setLogger({
  log: (message) => {
    Sentry.captureMessage(message);
  },
  warn: (message) => {
    Sentry.captureMessage(message);
  },
  error: (error) => {
    Sentry.captureException(error);
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 5 * 60 * 1000, // 5 minutes
      useErrorBoundary: (e) => {
        switch (e.constructor) {
          case NotFoundError:
          case Unauthorized:
            return false;
          default:
            return true;
        }
      },
    },
    mutations: {
      useErrorBoundary: (e) => {
        switch (e.constructor) {
          case Unauthorized:
            // Return false when is a whitelist error.
            return false;
          default:
            return true;
        }
      },
    },
  },
});

type Props = {
  children: ReactNode;
};

export const ReactQueryConfig = ({ children }: Props) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
