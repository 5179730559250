import { FileSearchOutlined, FileUnknownOutlined } from "@ant-design/icons";
import { Button, Result } from "antd";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { ReactNode } from "react";

import { Flex } from "~/src/components/flex";
import { HelpLink } from "~/src/components/help-link";
import { Translation } from "~/src/components/translation";
import { useSetFullHeight } from "~/src/lib/use-set-full-height";

type Props = {
  statusCode?: number;
  extra?: ReactNode;
  icon?: ReactNode;
  title?: ReactNode;
  message?: ReactNode;
};

/**
 * An error alert that fills the entire screen.
 */
const Error = ({
  extra,
  icon = <FileSearchOutlined />,
  title,
  message,
}: Props) => {
  const { t } = useTranslation("common");

  const elementRef = useSetFullHeight<HTMLDivElement>();

  const defaultTitle = t("common:error.info.default.heading");
  const defaultMessage = (
    <Translation
      i18nKey="common:error.info.default.message"
      components={{ a: <HelpLink>{null}</HelpLink> }}
    />
  );

  return (
    <Flex justify="center" ref={elementRef}>
      <Result
        icon={icon}
        title={title ?? defaultTitle}
        subTitle={message ?? defaultMessage}
        extra={extra}
      />
    </Flex>
  );
};

/**
 * Content for 404 error page.
 */
export const NotFoundError = () => {
  const router = useRouter();
  const { t } = useTranslation("common");

  return (
    <Error
      icon={<FileUnknownOutlined />}
      message={
        <Translation
          i18nKey={`common:error.info.404.message`}
          components={{
            a: <HelpLink>{null}</HelpLink>,
          }}
          tOptions={{ returnObjects: true }}
        />
      }
      title={t("common:error.info.404.heading")}
      extra={
        <Flex gap="lg" justify="center">
          <Button size="large" onClick={() => router.back()}>
            {t("common:error.back")}
          </Button>
          <Link href="/" passHref>
            <Button size="large" type="primary">
              {t("common:error.home")}
            </Button>
          </Link>
        </Flex>
      }
    />
  );
};

/**
 * Content for 500 error page.
 */
export const ServerError = () => {
  const { t } = useTranslation("common");
  const router = useRouter();

  return (
    <Error
      extra={
        <Flex gap="lg" justify="center">
          <Button size="large" onClick={() => router.back()}>
            {t("common:error.back")}
          </Button>
          <Button type="primary" size="large" onClick={() => router.reload()}>
            {t("common:error.retry")}
          </Button>
        </Flex>
      }
    />
  );
};

/**
 * Error used when an error happens on the client side.
 */
export const ClientError = ({
  title,
  message,
}: {
  title?: string;
  message?: string;
}) => {
  const { t } = useTranslation("common");
  const router = useRouter();

  return (
    <Error
      title={title}
      message={message}
      extra={
        <Flex gap="lg" justify="center">
          <Button size="large" onClick={() => router.reload()}>
            {t("common:error.refresh")}
          </Button>
          <Link href="/" passHref>
            <Button size="large" type="primary">
              {t("common:error.home")}
            </Button>
          </Link>
        </Flex>
      }
    />
  );
};
