import { ThemeConfig } from "antd-v5";

export const themeConfig: ThemeConfig = {
  cssVar: true,
  token: {
    fontFamily: "Roboto, sans-serif",
    colorText: "hsla(0 0% 0% 0.85)",

    colorPrimary: "hsl(227 95% 44%)",
    colorPrimaryBg: "hsl(213 60% 97%)",
    colorBgContainerDisabled: "hsl(0 0% 70%)",
    colorTextDisabled: "hsl(0 0 60%)",
    fontWeightStrong: 700, // 600.

    borderRadius: 5,
    borderRadiusSM: 2,
  },
  components: {
    Table: {
      rowHoverBg: "hsl(213 60% 97%)",
    },
  },
};
