import Link from "next/link";
import { ReactNode } from "react";

import { getSupportUrl } from "~/src/lib/get-urls";

type Props = {
  children: ReactNode;
  passHref?: boolean;
};

export const HelpLink = ({ children, passHref = false }: Props) => {
  return (
    <Link href={getSupportUrl()} passHref={passHref} target="_blank">
      {children}
    </Link>
  );
};
