import * as dayjs from "dayjs";
import { default as relativeTime } from "dayjs/plugin/relativeTime";
import { default as isSameOrAfter } from "dayjs/plugin/isSameOrAfter";
import { default as isSameOrBefore } from "dayjs/plugin/isSameOrBefore";
import { default as duration } from "dayjs/plugin/duration";
import { default as utc } from "dayjs/plugin/utc";

// This is necessary to improve time accuracy.
// @see https://day.js.org/docs/en/customization/relative-time
const relativeTimeThresholds = [
  { l: "s", r: 1 },
  { l: "m", r: 1 },
  { l: "mm", r: 59, d: "minute" },
  { l: "h", r: 1 },
  { l: "hh", r: 23, d: "hour" },
  { l: "d", r: 1 },
  { l: "dd", r: 29, d: "day" },
  { l: "M", r: 1 },
  { l: "MM", r: 11, d: "month" },
  { l: "y", r: 1 },
  { l: "yy", d: "year" },
];

dayjs.extend(relativeTime, { thresholds: relativeTimeThresholds });
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(duration);
dayjs.extend(utc);
