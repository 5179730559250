import c from "classnames";
import {
  Children,
  CSSProperties,
  forwardRef,
  HTMLProps,
  isValidElement,
  ReactNode,
  RefObject,
} from "react";

import stylesheet from "./style.module.css";

import { getMergedProps } from "~/src/lib/get-merged-props";

export type FlexGap = "sm" | "md" | "lg" | "xl" | number;

type FlexProps = {
  children?: ReactNode;
  direction?: "horizontal" | "vertical";
  sparsed?: boolean;
  align?: CSSProperties["alignItems"];
  justify?: CSSProperties["justifyContent"];
  gap?: FlexGap;
  className?: string;
  style?: CSSProperties;
};

const getGapProps = (gap: FlexProps["gap"]): HTMLProps<HTMLDivElement> => {
  if (typeof gap === "string") {
    return { className: stylesheet[`gap-${gap}`] };
  } else if (typeof gap === "number") {
    return { style: { "--gap": `${gap}px` } as CSSProperties };
  }
  return {};
};

const getAlignmentProps = (
  align: FlexProps["align"],
  justify: FlexProps["justify"],
): HTMLProps<HTMLDivElement> => {
  return { style: { alignItems: align, justifyContent: justify } };
};

const Flex = forwardRef(
  (
    {
      direction = "horizontal",
      sparsed = false,
      gap = 0,
      align,
      justify,
      children,
      className,
      style,
    }: FlexProps,
    ref: RefObject<HTMLDivElement>,
  ) => {
    const props = getMergedProps<HTMLProps<HTMLDivElement>>(
      {
        className: c(stylesheet.flex, stylesheet[direction], className, {
          [stylesheet.sparsed]: sparsed,
        }),
      },
      getGapProps(gap),
      getAlignmentProps(align, justify),
      { style, ref },
    );
    return (
      <div {...props}>
        {Children.map(children, (child) =>
          child ? (
            isValidElement(child) && child.type === Item ? (
              child
            ) : (
              <Item>{child}</Item>
            )
          ) : null,
        )}
      </div>
    );
  },
);

Flex.displayName = "Flex";

type ItemProps = {
  children?: ReactNode;
  grow?: number;
  shrink?: number;
  basis?: string;
  className?: string;
  style?: CSSProperties;
};

/**
 * Flex Items must be direct descendants of Flex. They're also injected automatically when omitted. Only used when you need to customized its props. Forwardable reference.
 */
const Item = forwardRef(
  (
    {
      children,
      className,
      style,
      grow = 0,
      shrink = 1,
      basis = "auto",
    }: ItemProps,
    ref: RefObject<HTMLDivElement>,
  ) => {
    const props = {
      className: c(stylesheet.item, className),
      style: { flex: `${grow} ${shrink} ${basis}`, ...style },
    };
    return (
      <div ref={ref} {...props}>
        {children}
      </div>
    );
  },
);

Item.displayName = "Flex.Item";

// We don't use our conventional way to export components because the Flex
// component is using forwardRef(), then add sub-components doesn't work with
// dot notation. It throws a typescript error: Property 'Item' does not exist
// on type 'ForwardRefExoticComponent<FlexProps & ...'
// see https://github.com/DefinitelyTyped/DefinitelyTyped/issues/34757
const FlexNamespace = Object.assign(Flex, { Item });
export { FlexNamespace as Flex };
