import { Modal } from "antd-v5";
import { createContext, ReactNode, useContext, useMemo } from "react";

const Context = createContext<ReturnType<typeof Modal.useModal>[0]>(null);

type Props = {
  children?: ReactNode;
};

export const Provider = ({ children }: Props) => {
  const [modal, contextHolder] = Modal.useModal();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const value = useMemo(() => modal, []);

  return (
    <Context.Provider value={value}>
      {contextHolder}
      {children}
    </Context.Provider>
  );
};

/**
 * Access to modal static functions.
 * @see https://ant.design/components/modal/#components-modal-demo-hooks
 */
export const useModal = () => {
  return useContext(Context);
};
